import React, { useState, useEffect, useRef } from 'react';
import {
  Stack,
  Text,
  Button,
  Img,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import { MdSend, MdFileDownload } from 'react-icons/md';
import Footer from '../Footer';
import Sidebar from './Sidebar';
import Drawer from './Drawer';
import avatar2 from '../../assets/images/hairLogo.png';
import Logo from '../../assets/images/hairLogo.png';
import loader from '../../assets/images/loading.gif';
import sample from '../../assets/images/sample.png';
import NameAvatar from '../../utils/NameAvatar';
import axios from 'axios';
import JSZip from 'jszip';
import { BsPlusCircleFill } from 'react-icons/bs';
import { BiDonateHeart } from "react-icons/bi";
const Webchat = () => {
  const [showChat, setShowChat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const faqRefs = useRef([]);
  const inputRef = useRef(null);
  const toast = useToast();

  const sampleQuestionArray = [
    {
      image: sample,
      title: 'Hair Care Routine',
      question1: 'What is the best daily hair care routine for healthy hairs?',
      question2: 'How often should I wash my hair?',
    },
    {
      question1: 'What are some effective ways to promote hair growth?',
      question2: 'Do vitamins help in hair growth?',
    },
    {
      question1: 'What are the benefits of keratin treatment for hair?',
      question2: 'Is it safe to color my hair frequently?',
    },
  ];

  useEffect(() => {
    if (faqs.length > 0) {
      const lastFaqIndex = faqs.length - 1;
      faqRefs.current[lastFaqIndex]?.scrollIntoView({ behavior: 'smooth' });
      inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [faqs]);

  const handleZipResponse = async data => {
    const zip = await JSZip.loadAsync(data);
    const files = [];
    zip.forEach((relativePath, file) => {
      files.push(
        file.async('base64').then(content => ({ name: relativePath, content }))
      );
    });
    return Promise.all(files);
  };

  const createChat = async ques => {
    const currentques = ques || query;
    setLoading(true);
    if (ques) {
      setShowChat(true);
    }
    setFaqs(prevFaqs => [
      ...prevFaqs,
      {
        query: currentques,
        answer: 'Awaiting answer...',
        images: [],
      },
    ]);
    setQuery('');

    try {
      const response = await axios.post(
        'https://api.hairadvisor.ai/query',
        { user_input: currentques, chat_history: [] },
        { responseType: 'arraybuffer' }
      );

      let content = '',
        images = [];
      if (response.headers['content-type'] === 'application/zip') {
        const files = await handleZipResponse(response.data);
        images = files;
      } else {
        const responseData = JSON.parse(
          new TextDecoder().decode(new Uint8Array(response.data))
        );
        content = responseData.content;
        images = responseData.images || [];
      }

      setFaqs(prevFaqs =>
        prevFaqs.map((faq, index) =>
          index === prevFaqs.length - 1
            ? { ...faq, answer: content, images: images.length ? images : [] }
            : faq
        )
      );

      const historyObj = {
        role: 'user',
        content: currentques,
      };

      if (content) {
        setChatHistory(prevHistory => [
          ...prevHistory,
          historyObj,
          {
            role: 'assistant',
            content,
          },
        ]);
      }
    } catch (error) {
      console.error('Error creating chat:', error);
      setFaqs(prevFaqs =>
        prevFaqs.map((faq, index) =>
          index === prevFaqs.length - 1
            ? { ...faq, answer: 'Error retrieving answer' }
            : faq
        )
      );
    } finally {
      setLoading(false);
    }
  };

  const updateChat = async () => {
    setLoading(true);
    setFaqs(prevFaqs => [
      ...prevFaqs,
      { query: query, answer: 'Awaiting answer...', images: [] },
    ]);
    const currentques = query;
    setQuery('');

    try {
      const response = await axios.post(
        'https://api.hairadvisor.ai./query',
        { user_input: currentques, chat_history: chatHistory },
        { responseType: 'arraybuffer' }
      );

      let content = '',
        images = [];
      if (response.headers['content-type'] === 'application/zip') {
        const files = await handleZipResponse(response.data);
        images = files;
      } else {
        const responseData = JSON.parse(
          new TextDecoder().decode(new Uint8Array(response.data))
        );
        content = responseData.content;
        images = responseData.images || [];
      }

      setFaqs(prevFaqs =>
        prevFaqs.map((faq, index) =>
          index === prevFaqs.length - 1
            ? { ...faq, answer: content, images: images.length ? images : [] }
            : faq
        )
      );

      const historyObj = {
        role: 'user',
        content: currentques,
      };

      if (content) {
        setChatHistory(prevHistory => [
          ...prevHistory,
          historyObj,
          {
            role: 'assistant',
            content,
          },
        ]);
      }
    } catch (error) {
      console.error('Error updating chat:', error);
      setFaqs(prevFaqs =>
        prevFaqs.map((faq, index) =>
          index === prevFaqs.length - 1
            ? { ...faq, answer: 'Error retrieving answer' }
            : faq
        )
      );
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter' && loading === false) {
      if (faqs.length > 0) {
        updateChat();
      } else {
        setShowChat(true);
        createChat();
      }
    }
  };

  const handleChat = () => {
    setShowChat(false);
    setChatHistory([]);
    setFaqs([]);
  };

  const downloadImage = (base64Image, filename) => {
    const link = document.createElement('a');
    link.href = `data:image/png;base64,${base64Image}`;
    link.download = filename;
    link.click();
  };

  const renderText = text => {
    const lines = text
      ?.split(/\n/)
      .filter(
        (line, index, array) =>
          line.length >= 5 ||
          (line.length === 0 && array[index - 1]?.length !== 0)
      );

    return lines?.map((line, index) => {
      let formattedLine = line.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
      if (formattedLine.startsWith('###')) {
        formattedLine = `<h3>${formattedLine.slice(3).trim()}</h3>`;
      }
      return line.length === 0 ? (
        <br key={index} />
      ) : (
        <p key={index} dangerouslySetInnerHTML={{ __html: formattedLine }} />
      );
    });
  };

  const handleNavigate = () => {
    window.open("https://www.paypal.com/donate/?hosted_button_id=K87FNQAS6CM42");
  }
  return (
    <Stack
      fontFamily={'Livvic !important'}
      direction={{ base: 'column', md: 'row' }}
      width={'100%'}
    >
      <Stack spacing={16} width={'100%'} px={2}>
        <Stack>
          {!showChat ? (
            <Stack
              mt={0.5}
              py={8}
              minH={'100vh'}
              justifyContent={'space-between'}
              w="100%"
              alignItems={'center'}
            >
              <Stack
                px={{ base: 2, lg: 12 }}
                w="100%"
                justifyContent={'space-between'}
                direction={{ base: 'column', md: 'row' }}
                gap={4}
              >
                <HStack alignSelf={{ base: 'center', md: 'start' }} spacing={6}>
                  <Img w={10} src={Logo} />
                  <Heading
                    color={'#1B2559'}
                    fontWeight={'700'}
                    fontSize={{ base: '20px', lg: '28px' }}
                    fontFamily={'Livvic !important'}
                  >
                    Hair Advisor Chat
                  </Heading>
                </HStack>
                <HStack direction="row" justifyContent="space-between">
                  <HStack
                    onClick={handleChat}
                    cursor={'pointer'}
                    bgColor={'rgba(247, 249, 251, 1)'}
                    borderRadius={'10px'}
                    px={4}
                    py={2}
                    w={'fit-content'}
                    alignSelf={'center'}
                  >
                    <BsPlusCircleFill size={20} />
                    <Text color={'#1C1C1C'} fontSize={'sm'} fontWeight={'400'}>
                      New Chat
                    </Text>
                  </HStack>
                  <HStack
                    onClick={handleNavigate}
                    cursor={'pointer'}
                    bgColor={'rgba(247, 249, 251, 1)'}
                    borderRadius={'10px'}
                    px={4}
                    py={2}
                    w={'fit-content'}
                    alignSelf={'center'}
                  >
                    {/* <BsPlusCircleFill size={20} /> */}
                    <BiDonateHeart size={20} />
                    <Text color={'#1C1C1C'} fontSize={'sm'} fontWeight={'400'}>
                      Contribute
                    </Text>
                  </HStack>
                </HStack>
              </Stack>
              <Stack alignItems={'center'}>
                <Stack w={'90%'}>
                  <Stack pt={4} alignItems={'center'}>
                    <Img w={6} src={sample} />
                    <Heading fontFamily={'Livvic'} fontSize="md">
                      Sample Questions
                    </Heading>
                  </Stack>
                  <SimpleGrid pt={8} spacing={6} columns={[1, 1, 2, 3, 3]}>
                    {sampleQuestionArray.map((obj, index) => (
                      <Stack key={index} spacing={5} alignItems={'center'}>
                        <Stack
                          justifyContent={'center'}
                          fontSize={'xs'}
                          onClick={() => createChat(obj.question1)}
                          textAlign={'center'}
                          py={4}
                          px={2}
                          height={'70px'}
                          w="100%"
                          borderRadius={'2xl'}
                          border="1px solid #418DB8"
                          _hover={{
                            cursor: 'pointer',
                            bgColor: '#418DB8',
                            color: 'white',
                          }}
                          boxShadow={
                            '0px 0px 40px 0px rgba(145, 158, 171, 0.35);'
                          }
                          bg="white"
                          color="#637381"
                        >
                          <Text>{obj.question1}</Text>
                        </Stack>
                        <Stack
                          justifyContent={'center'}
                          fontSize={'xs'}
                          onClick={() => createChat(obj.question2)}
                          textAlign={'center'}
                          py={4}
                          px={2}
                          height={'70px'}
                          w="100%"
                          borderRadius={'2xl'}
                          border="1px solid #418DB8"
                          _hover={{
                            cursor: 'pointer',
                            bgColor: '#418DB8',
                            color: 'white',
                          }}
                          boxShadow={
                            '0px 0px 40px 0px rgba(145, 158, 171, 0.35);'
                          }
                          bg="white"
                          color="#637381"
                        >
                          <Text>{obj.question2}</Text>
                        </Stack>
                      </Stack>
                    ))}
                  </SimpleGrid>
                </Stack>
              </Stack>
              <Stack alignItems={'center'} w={'100%'}>
                <InputGroup
                  mt={{ base: 12 }}
                  border="1px solid #418DB8"
                  borderRadius={'lg'}
                  size="lg"
                  fontSize={'md'}
                  boxShadow={'0px 0px 40px 0px rgba(145, 158, 171, 0.35);'}
                  w={{ base: '100%', md: '90%', lg: '70%' }} // Adjusted width based on screen size
                  maxW="900px" // Set a maximum width
                >
                  <Input
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    onKeyDown={handleKeyPress}
                    placeholder="Ask a question"
                    _placeholder={{ fontSize: 14 }}
                  />
                  <InputRightElement>
                    <MdSend
                      cursor={query.length > 0 ? 'pointer' : ''}
                      onClick={() => {
                        if (query && query.trim().length !== 0) {
                          setShowChat(true);
                          createChat();
                        }
                      }}
                      color="green.500"
                    />
                  </InputRightElement>
                </InputGroup>
              </Stack>
            </Stack>
          ) : (
            <>
              <Stack minH={'80vh'} py={8} justifyContent={'space-between'}>
                <Stack
                  px={{ base: 2, lg: 12 }}
                  w="100%"
                  justifyContent={'space-between'}
                  direction={{ base: 'column', md: 'row' }}
                  gap={4}
                >
                  <HStack
                    alignSelf={{ base: 'center', md: 'start' }}
                    spacing={6}
                  >
                    <Img w={8} src={Logo} />
                    <Heading
                      color={'#1B2559'}
                      fontWeight={'700'}
                      fontSize={{ base: '20px', lg: '28px' }}
                      fontFamily={'Livvic !important'}
                    >
                      Hair Advisor Chat
                    </Heading>
                  </HStack>
                  <HStack direction="row" justifyContent="space-between">
                    <HStack
                      onClick={handleChat}
                      cursor={'pointer'}
                      bgColor={'rgba(247, 249, 251, 1)'}
                      borderRadius={'10px'}
                      px={4}
                      py={2}
                      w={'fit-content'}
                      alignSelf={'center'}
                    >
                      <BsPlusCircleFill size={20} />
                      <Text color={'#1C1C1C'} fontSize={'sm'} fontWeight={'400'}>
                        New Chat
                      </Text>
                    </HStack>
                    <HStack
                      onClick={handleNavigate}
                      cursor={'pointer'}
                      bgColor={'rgba(247, 249, 251, 1)'}
                      borderRadius={'10px'}
                      px={4}
                      py={2}
                      w={'fit-content'}
                      alignSelf={'center'}
                    >
                      {/* <BsPlusCircleFill size={20} /> */}
                      <BiDonateHeart size={20} />
                      <Text color={'#1C1C1C'} fontSize={'sm'} fontWeight={'400'}>
                        Contribute
                      </Text>
                    </HStack>
                  </HStack>
                </Stack>
                <Stack
                  pt={2}
                  maxH={'68vh'}
                  h={'68vh'}
                  id="chat-container"
                  overflowY={'auto'}
                >
                  {faqs.map((faq, index) => (
                    <Stack
                      key={index}
                      mb={4}
                      w="100%"
                      alignItems={'center'}
                      ref={el => (faqRefs.current[index] = el)}
                    >
                      <HStack
                        w="100%"
                        p={2}
                        justifyContent={{ base: 'normal', lg: 'center' }}
                        alignItems={'start'}
                      >
                        <NameAvatar name={'User'} />
                        <Stack
                          border={'1px solid #E2E8F0'}
                          borderRadius={'10px'}
                          p={3}
                          w={'80%'}
                        >
                          <Text
                            color={'#1C1C1C'}
                            fontSize={'15px'}
                            fontWeight={'600'}
                            lineHeight={1.7}
                          >
                            {faq.query}
                          </Text>
                        </Stack>
                      </HStack>
                      <HStack
                        w="100%"
                        p={2}
                        justifyContent={{ base: 'normal', lg: 'center' }}
                      >
                        <Img w={8} src={avatar2} alignSelf={'baseline'} />
                        <Stack
                          borderRadius={'10px'}
                          bgColor={'#FFFFF'}
                          boxShadow={'lg'}
                          p={3}
                          w={'80%'}
                          gap={3}
                        >
                          {faq.answer === 'Awaiting answer...' ? (
                            <Img alignSelf={'center'} w={14} src={loader} />
                          ) : (
                            <>
                              <HStack
                                justifyContent={'space-between'}
                                alignItems={'baseline'}
                                spacing={4}
                              >
                                <Text
                                  color={'#1B2559'}
                                  fontSize={'14px'}
                                  fontWeight={'400'}
                                  w="90%"
                                >
                                  {renderText(faq.answer)}
                                </Text>
                              </HStack>
                              {faq.images && faq.images.length > 0 && (
                                <SimpleGrid columns={2} spacing={4}>
                                  {faq.images.map((image, idx) => (
                                    <Stack
                                      key={idx}
                                      position="relative"
                                      _hover={{
                                        '.download-icon': { opacity: 1 },
                                      }}
                                    >
                                      <Img
                                        src={`data:image/png;base64,${image.content}`}
                                      />
                                      <IconButton
                                        icon={<MdFileDownload />}
                                        position="absolute"
                                        minWidth={'1.5rem'}
                                        height={'1.5rem'}
                                        borderRadius={0}
                                        top="0"
                                        right="0"
                                        size="md"
                                        opacity="0"
                                        className="download-icon"
                                        // _hover={{ transform: 'scale(1.3)' }}
                                        onClick={() =>
                                          downloadImage(
                                            image.content,
                                            `image${idx}.png`
                                          )
                                        }
                                        aria-label="Download Image"
                                      />
                                    </Stack>
                                  ))}
                                </SimpleGrid>
                              )}
                              {faq.files && faq.files.length > 0 && (
                                <SimpleGrid columns={2} spacing={4}>
                                  {faq.files.map((file, idx) => (
                                    <div key={idx}>
                                      <p>{file.name}</p>
                                      <Img
                                        src={`data:image/png;base64,${file.content}`}
                                      />
                                    </div>
                                  ))}
                                </SimpleGrid>
                              )}
                            </>
                          )}
                        </Stack>
                      </HStack>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
              <Stack>
                <HStack
                  mt={{ base: '0px', md: '4px' }}
                  mb={{ base: '10px', md: '0px' }}
                  justifyContent={'center'}
                >
                  <Stack
                    width={{ base: '70%', md: '75%', lg: '60%' }}
                    maxW="600px"
                  >
                    <Input
                      className="input-field"
                      placeholder="Ask a question"
                      type="text"
                      value={query}
                      onChange={e => setQuery(e.target.value)}
                      border={'1px solid rgba(226, 232, 240, 1)'}
                      borderRadius={'25px'}
                      fontSize={'14px'}
                      isDisabled={loading}
                      onKeyPress={handleKeyPress}
                    // ref={inputRef}
                    />
                  </Stack>
                  <Button
                    width={{ base: '30%', md: '20%', lg: '15%' }}
                    px={{ base: '12px', md: '16px', lg: '20px' }} // Reduced padding
                    borderRadius={'45px'}
                    color={'white'}
                    fontSize={'14px'}
                    _hover={{}}
                    fontWeight={'600'}
                    bgGradient="linear-gradient(15deg, #17496D 0%, #418DB8 92.71%)"
                    onClick={updateChat}
                    isDisabled={loading || !query || query.trim().length === 0}
                  >
                    Submit
                  </Button>
                </HStack>
              </Stack>
            </>
          )}
        </Stack>

        <Footer />
      </Stack>
    </Stack>
  );
};

export default Webchat;
